import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import "../assets/styles/css/styles.css"


const useStyles = makeStyles((theme) => ({
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    infoContent: {
        padding: theme.spacing(8, 0, 7),
    },
    requirementsIcon: {
        color: theme.palette.success.main,
    },
    closebutton:{
      float: 'right',
      color: 'black',
      fontSize: '13px',
      paddingTop: '0px'
    },
    modal: {
      display: 'grid',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
      position:'absolute',
      top:'10%',
      left:'10%',
      height:'100%',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
}));


export default function Programs() {
  const classes = useStyles();

  const buttons = [
    <Button key="main" href="https://docs.google.com/document/d/e/2PACX-1vRhjJL-gHgICqkonYhTy-BCwnQ9zQLJ02uXJsW0zduGG0chBLnP05g0l9XcyuCe2X7DHlIFtxODmDSV/pub">Main Campus</Button>,
    <Button key="balilihan" href="https://docs.google.com/document/d/e/2PACX-1vRu00nWpxWtPTCTJ8PzHiMwY4PfBUb2c_Gb6S8BMICA-vVzF1xuqW3qovMycj0TV46oPD2A7YvzyAhL/pub">Balilihan Campus</Button>,
    <Button key="bilar" href="https://docs.google.com/document/d/e/2PACX-1vRYe2cmS_OYi34G7150_Ka-wyH-QQ07K3pzFWtjf2Y2lAJEzvxu_V7UtRgAocavYUwhYAog6ZjrxEA-/pub">Bilar Campus</Button>,
    <Button key="calape" href="https://docs.google.com/document/d/e/2PACX-1vRlcyIsUPfxH4HO5RqxofKwp5-aDfTpg__x9VFRnFHNfwMIXVgRZEgciPn14csI6iOhVZzT0xM_wgJM/pub">Calape Campus</Button>,
    <Button key="candijay" href="https://docs.google.com/document/d/e/2PACX-1vT6XuOWx84vqbHMq79IVO0R5P4b_RxDm7_JmCMn_TuomgNX2LFA0rzmQChQZHH-V3pB6U1DTF5KAqvu/pub">Candijay Campus</Button>,
    <Button key="clarin" href="https://docs.google.com/document/d/e/2PACX-1vTV83zJ9Qv5aiWH3_yp_Oi4ODVVMTIW8dYqSUDgwmfG34wgYYPWqYnnJMl0sFhY81cq2zC_3wx8jl0Z/pub">Clarin Campus</Button>,
  ];

  return(
    <main>
        <div className={classes.heroContent}>
            <Container maxWidth="sm">
                <Typography component="h1" variant="h2" align="center" color="Primary" gutterBottom>
                Programs Offerings for A.Y. 2025-2026
                </Typography>
                <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                      <Button variant="contained" color="primary" href="/apply">
                          APPLY NOW
                      </Button>
                    </Grid>
                </Grid>
                {/* <Typography variant="body2" align="center" color="textSecondary">
                    <em><small>Application period is currently close.</small></em>
                </Typography> */}
                </div>
            </Container>
        </div>
        <Divider />
        <div className={classes.infoContent}>
          <Grid container spacing={2} justify="center">
              <Grid item>
                <ButtonGroup
                  orientation="vertical"
                  aria-label="vertical contained button group"
                  variant="text"
                >
                  {buttons}
                </ButtonGroup>

              </Grid>
          </Grid>
        </div>
    </main>
  )

}
