import React, { useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

function filterTowns(provinceID, towns) {
  if (!towns) {
    return [];
  }
  return towns.filter(town => {
    return town.province_id === provinceID;
  });
}

const PersonalInformation = (props) => {
  const classes = useStyles();
  const { personalInfo, setPersonalInfo, provinces, towns } = props;
  const filteredTowns = useMemo(() => filterTowns(personalInfo.province_id, towns), [personalInfo.province_id, towns]);
  const handleChange = (event) => {
    if (event.target.name === "province_id") {
      setPersonalInfo({
        ...personalInfo,
        area_id: 0,
        zip_code: "",
        [event.target.name]: event.target.value,
      });

    } else if (event.target.name === "area_id") {
      const zc = towns.find(item => item.id === event.target.value).zip_code;
      setPersonalInfo({
        ...personalInfo,
        zip_code: zc,
        [event.target.name]: event.target.value,
      })
    } else {
      setPersonalInfo({
        ...personalInfo,
        [event.target.name]: event.target.value,
      });
    }

  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField name="last_name" label="Last Name" value={personalInfo.last_name} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField name="first_name" label="First Name" value={personalInfo.first_name} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField name="middle_name" label="Middle Name" value={personalInfo.middle_name} onChange={handleChange} fullWidth />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <TextField name="suffix_name" label="Suffix Name" value={personalInfo.suffix_name} onChange={handleChange} placeholder="Sr / Jr" fullWidth />
        </Grid> */}
        <Grid item xs={12} sm={3} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="suffix_name">
              Suffix Name
            </InputLabel>
            <Select
              labelId="suffix_name"
              id="suffix_name"
              value={personalInfo.suffix_name}
              onChange={handleChange}
              inputProps={{ name: "suffix_name" }}
              displayEmpty
            >
              <MenuItem disabled value=""><em>Select</em></MenuItem>
              <MenuItem value="Jr">Jr</MenuItem>
              <MenuItem value="Sr">Sr</MenuItem>
              <MenuItem value="I">I</MenuItem>
              <MenuItem value="II">II</MenuItem>
              <MenuItem value="III">III</MenuItem>
              <MenuItem value="IV">IV</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="gender">
              Gender
            </InputLabel>
            <Select
              labelId="gender"
              id="gender"
              value={personalInfo.sex}
              onChange={handleChange}
              inputProps={{ name: "sex" }}
              displayEmpty
            >
              <MenuItem disabled value=""><em>Select</em></MenuItem>
              <MenuItem value="MALE">Male</MenuItem>
              <MenuItem value="FEMALE">Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <FormControl fullWidth>
            <InputLabel shrink id="civil_status">
              Civil Status
          </InputLabel>
            <Select
              labelId="civil_status"
              id="civil_status"
              value={personalInfo.civil_status}
              onChange={handleChange}
              inputProps={{ name: "civil_status" }}
              displayEmpty
            >
              <MenuItem disabled value=""><em>Select</em></MenuItem>
              <MenuItem value="SINGLE">Single</MenuItem>
              <MenuItem value="MARRIED">Married</MenuItem>
              <MenuItem value="DIVORCED">Divorced</MenuItem>
              <MenuItem value="SEPARATED">Separated</MenuItem>
              <MenuItem value="WIDOWED">Widowed</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField name="citizenship" label="Citizenship" value={personalInfo.citizenship} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField name="dob" label="Date of Birth (Jan 2, 2006)" value={personalInfo.dob} placeholder="Jan 2, 2006" onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={9} md={10}>
          <TextField name="pob" label="Place of Birth" value={personalInfo.pob} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel shrink id="province">
              Province
          </InputLabel>
            <Select
              labelId="province"
              id="province"
              value={personalInfo.province_id}
              onChange={handleChange}
              inputProps={{ name: "province_id" }}
              displayEmpty
            >
              <MenuItem value={0}><em>Select</em></MenuItem>
              {
                provinces && provinces.map(province => {
                  return (
                    <MenuItem key={province.id} value={province.id}>
                      {province.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel shrink id="town">
              Municipality/Town
          </InputLabel>
            <Select
              labelId="town"
              id="town"
              value={personalInfo.area_id}
              onChange={handleChange}
              inputProps={{ name: "area_id" }}
              displayEmpty
            >
              <MenuItem value={0}><em>Select</em></MenuItem>
              {
                filteredTowns && filteredTowns.map(town => {
                  return (
                    <MenuItem key={town.id} value={town.id}>
                      {town.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <TextField name="house_no_street_brgy" label="House No./Street/Brgy." value={personalInfo.house_no_street_brgy} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField name="zip_code" label="ZIP Code" value={personalInfo.zip_code} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} md={10}>
          <TextField name="alt_address" label="Alternate Address" value={personalInfo.alt_address} onChange={handleChange} fullWidth placeholder="fill this up if your address is not found in the above options" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField name="contact_no" label="Contact No." value={personalInfo.contact_no} placeholder="09123456789" onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={7}>
          <TextField name="email" label="Email" value={personalInfo.email} placeholder="example@email.com" onChange={handleChange} fullWidth inputProps={{ type: "email" }} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="name_father" label="Name of Father" value={personalInfo.name_father} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField name="name_mother" label="Name of Mother" value={personalInfo.name_mother} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField name="address_parents" label="Parent's Address" value={personalInfo.address_parents} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel shrink id="income_status">
              Income Bracket of the Family
          </InputLabel>
            <Select
              labelId="income_status"
              id="income_status"
              value={personalInfo.income_status}
              onChange={handleChange}
              inputProps={{ name: "income_status" }}
              displayEmpty
            >
              <MenuItem disabled value=""><em>Select</em></MenuItem>
              <MenuItem value="1"><b>below Php. 10,957</b>&nbsp; monthly income - Poor income cluster </MenuItem>
              <MenuItem value="2"><b>between Php. 10,957-21,914</b>&nbsp; monthly income - Low-income cluster</MenuItem>
              <MenuItem value="3"><b>between Php. 21,914-43,828</b>&nbsp; monthly income - Lower middle-income cluster</MenuItem>
              <MenuItem value="4"><b>between Php. 43,828-76,699</b>&nbsp; monthly income - Middle middle-income cluster</MenuItem>
              <MenuItem value="5"><b>between Php. 76,699-131,484</b>&nbsp; monthly income- Upper middle-income cluster</MenuItem>
              <MenuItem value="6"><b>between Php. 131,484-219,140</b>&nbsp; monthly income - Upper-income cluster</MenuItem>
              <MenuItem value="7"><b>above Php. 219,140</b>&nbsp; monthly income - Rich cluster</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Do you identify yourself as a Person with Disability (PWD) or other has a chronic condition?</FormLabel>
            <RadioGroup aria-label="is_pwd" name="is_pwd" value={personalInfo.is_pwd} onChange={handleChange}>
              <FormControlLabel value="true" control={<Radio  color="primary"/>} label="Yes" />
              <FormControlLabel value="false" control={<Radio  color="primary"/>} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Do you identify yourself as a member of indigenous people?</FormLabel>
            <RadioGroup aria-label="is_ip" name="is_ip" value={personalInfo.is_ip} onChange={handleChange}>
              <FormControlLabel value="true" control={<Radio  color="primary"/>} label="Yes" />
              <FormControlLabel value="false" control={<Radio  color="primary"/>} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

export default PersonalInformation;
